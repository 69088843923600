.ColorPickerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.ColorPickerContainer .item{
  border: 2px solid white;
  margin: 5px;
  padding: 5px;
  cursor: pointer;
  font-family: monospace;

  border-radius: 2px;
  box-shadow: 0 0 0 1px rgba(0,0,0,.4);
}
