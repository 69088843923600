.App {
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
}

/*.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}*/

.App-header {
  background-color: #282c34;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-top: 50px;
  padding-bottom: 50px;
  width: 50%;
  min-width: 400px;
}
.App-header-Light {
  background-color: #fff;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;
}

.controls{
  position: fixed;
  top: 0;
  left: 0;
  padding: 10px;
  width: 100%;
  z-index: 9999;
}


.controls .sliders {
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 30px;
  left:10px;
}

@media screen and (min-width: 500px) {
  .controls .sliders {
    top: 70px;
  }
}

.controls .settings {
  padding: 10px;
  background: rgba(0,0,0,0.5);
  color: white;
}

.controls .colors {
  padding: 10px;
  background: rgba(0,0,0,0.5);
  color: white;
}

svg{
  margin: 10px;
  border: 0;
}

.grayFilter {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

/*

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/
